import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth';
import { environment } from 'src/environments/environment';
import { NgxEchartsModule } from 'ngx-echarts';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FakeAPIService } from './_fake';
import { AuthHeaderInterceptor } from 'src/app/Http Interceptor/auth-header-interceptor';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { Router } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthHTTPService } from './modules/auth/services/auth-http/auth-http.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TreeModule } from '@ali-hm/angular-tree-component';
function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve:any) => {
      authService.getUserByToken().subscribe().add(() => resolve());

    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    TreeModule,
    NgbAccordionModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthHTTPService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {
    Sentry.init({
      dsn: "https://1d0c580be9f148f58fdfe85c2f9e6ce3@o1415196.ingest.sentry.io/6755677",
      integrations: [
        new BrowserTracing({
          tracingOrigins: ["localhost", "https://dev.reporting.etail-agency.com"],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
}
